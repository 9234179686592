<template>
  <section class="footer">
    <div class="columns">
      <div class="column is-6 is-offset-3">
        <div class="columns">
          <div class="column">
            <h3 class="subtitle is-uppercase">Cualquier duda contactanos</h3>
            <ul>
              <li>sac@worldkids.com.co</li>
              <li>soporte@worldkids.com.co</li>
            </ul>
          </div>
          <div class="column">
            <h3 class="subtitle is-uppercase">Redes sociales</h3>
            <a href="http://www.instagram.com/worldkids_gy" target="_blank">
              <i class="fab fa-instagram"></i> WorldKids_gy
            </a> <br>
            <a href="https://www.facebook.com/World-Kids-100739888874848" target="_blank">
              <i class="fab fa-facebook"></i> WorldKids
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style>
ul li {
  font-weight: 500;
  margin-bottom: 1em;
}
</style>