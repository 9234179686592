<template>
  <section class="section" id="mision">
    <div class="container" data-aos="fade-down" data-aos-duration="1000">
      <h3 class="subtitle is-uppercase" >Nuestra misión</h3>
      <h1 class="title">
        Facilitar información acerca de <br />
        <span class="has-text-info"
          >crianza asertiva, educación, <br />alimentación y salud</span
        >
        en los niños.
      </h1>
    </div> 
  </section>
</template>

<script>
export default {
  name: "Mision",
};
</script>

<style>
#mision {
  background-image: url("../static/mision.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 600px;
}
</style>