<template>
  <section class="section" id="action">
    <a name="suscripcion" id="suscripcion"></a>
    <div
      class="container is-flex is-justify-content-center is-align-content-center"
    >
      <div class="columns is-10 is-offset-1 is-variable is-7">
        <div class="column">
          <h3 class="subtitle is-uppercase has-text-white" id="actions">
            Padres con ganas de aprender
          </h3>
          <h1 class="title has-text-white mb-6">
            Ayudanos para dar una mejor experiencia
          </h1>
          <p class="has-text-white is-size-6 has-text-weight-light mb-5">
            ¿ Por qué has elegido a
            <strong class="has-text-white">World Kids</strong> como blog
            principal <br />
            de información sobre tema de niños ?
          </p>
          <p class="has-text-white has-text-weight-medium mb-3">
            "Es una de las plataformas mas completas que he usado hasta el
            momento, util para estar informado de temas relacionados con el
            cuidado general de los niños."
          </p>
          <p class="has-text-white is-size-6 has-text-weight-bold mt-5">
            Carlos Andres Delbarre de la Cruz
          </p>
          <p class="has-text-white is-size-7">
            Padre Primerizo de un niño de 2 años.
          </p>
        </div>
        <div class="column">
          <div
            class="box p-6"
            data-aos="fade-left"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="1000"
          >
            <h1 class="subtitle has-text-semibold is-size-4 has-text-centered">
              Solo te tomará unos segundos
            </h1>
            <b-field>
              <b-input
                type="text"
                placeholder="Nombre Completo"
                v-model="name"
              ></b-input>
            </b-field>

            <b-field>
              <b-input
                type="email"
                placeholder="Correo Electrónico"
                v-model="email"
              ></b-input>
            </b-field>

            <b-field>
              <b-input
                type="text"
                placeholder="Telefono"
                v-model="phoneNumber"
              ></b-input>
            </b-field>

            <b-button
              type="is-info"
              class="mt-4"
              :loading="isLoading"
              @click="enviarSuscriptor"
            >
              Enviar Información
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ToastProgrammatic as toast } from "buefy";
import _ from 'lodash/string'
import Firebase from "firebase/app";
import "firebase/database";

export default {
  name: "Action",
  data() {
    return {
      name: "",
      email: "",
      phoneNumber: "",
      isLoading: false,
    };
  },
  methods: {
    enviarSuscriptor() {
      if (this.verificarInfo()) return this.danger();

      this.isLoading = true;
      const nuevoSuscriptor = {
        nombre: this.formatearTexto(this.name),
        correo: this.formatearEmail(this.email),
        telefono: this.phoneNumber,
      };

      let db = Firebase.database();

      try {
        db.ref("Suscriptores")
          .push()
          .set(nuevoSuscriptor)
          .then(() => this.success());
      } catch (e) {
        this.isLoading = false
        console.log(e);
      }
    },
    verificarInfo() {
      if (this.name == "" || this.email == "" || this.phoneNumber == "")
        return true;
      else return false;
    },
    formatearTexto(value){
      const lower = _.toLower(value)
      const words = _.words(lower)
      const upperFirst = words.reduce(
        (acc, el) => acc + _.upperFirst(el) + ' '
        , "")

      return _.trim(upperFirst)
    },
    formatearEmail(value){
      return _.toLower(value)
    },
    danger() {
      toast.open({
        duration: 5000,
        message: `Algunos de los campos solicitados, <b>se encuentran vacios</b>`,
        position: "is-bottom",
        type: "is-danger",
      });
    },
    success() {
      this.isLoading = false;
      toast.open({
        duration: 5000,
        message: `Gracias por suscribirte a nuestra gran comunidad de World Kids!.`,
        position: "is-top",
        type: "is-success",
      });
      this.limpiarInputs();
    },
    limpiarInputs() {
      this.name = "";
      this.email = "";
      this.phoneNumber = "";
    },
  },
};
</script>

<style lang="css">
#action {
  background-image: url("../static/bg_actions.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 600px;
}

label {
  line-height: 1.1 !important;
}
strong {
  color: white;
}

.input {
  border-color: #9a9999 !important;
  margin-top: 0.5em;
}
h3.subtitle.is-uppercase#actions {
  color: rgb(255, 255, 255, 0.5) !important;
}
</style>