<template>
  <section class="section">
    <div class="container">
      <div data-aos="fade-down" data-aos-duration="1000">
        <h3 class="subtitle is-uppercase">Especialistas</h3>
        <h1 class="title has-text-weight-bold" data-aos-delay="300">
          Encontrarás opiniones de <br /><span class="has-text-info"
            >Medicos Especilistas</span
          >.
        </h1>
      </div>
      <div class="columns mt-6">
        <div
          class="column px-6"
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="1000"
        >
          <figure class="image is-128x128 mauto">
            <img class="is-rounded" :src="require('../static/pediatria.png')" />
          </figure>
          <h4 class="name has-text-weight-semibold">Pediatría</h4>
          <p class="is-size-8 has-text-weight-regular">
            Parte de la medicina que se ocupa del estudio del crecimiento y el
            desarrollo de los niños hasta la adolescencia, así como del
            tratamiento de sus enfermedades.
          </p>
        </div>
        <div class="column px-6" data-aos="zoom-in" data-aos-duration="1000">
          <figure class="image is-128x128 mauto">
            <img class="is-rounded" :src="require('../static/nutricion.png')" />
          </figure>
          <h4 class="name has-text-weight-semibold">Nutrición Infantil</h4>
          <p class="is-size-8 has-text-weight-regular">
            La nutrición infantil se refiere a todo lo que su hijo come y bebe.
            El cuerpo de su hijo utiliza nutrientes de los alimentos para
            funcionar correctamente y permanecer saludable.
          </p>
        </div>
        <div
          class="column px-6"
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="1000"
        >
          <figure class="image is-128x128 mauto">
            <img
              class="is-rounded"
              :src="require('../static/psiquatria.png')"
            />
          </figure>
          <h4 class="name has-text-weight-bold">Psicología Infantil</h4>
          <p class="is-size-8 has-text-weight-regular">
            Esta rama de la psicología se centra en la evolución de los aspectos
            físicos, motores, cognitivos, perceptivos, afectivos y sociales.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Doctor",
};
</script>

<style lang="css" scoped>
</style>