<template>
  <section>
    <div class="hero is-flex is-justify-content-center is-align-content-center" id="home">
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <div class="has-text-centered" data-aos="fade-down">
            <h3 class="subtitle is-uppercase">World Kids</h3>
            <h1 class="title">Para los padres que han revolucionado la forma de entender la paternidad</h1>
            <a href="#suscripcion" class="button is-info has-text-white">Solicitar unirme</a>
          </div>
        </div>
      </div>
    </div>

    <what-we-do />
    <doctor />
    <presentation />
    <mision/>
    <action/>
    <the-footer/>
  </section>
</template>

<script>
import Presentation from "../components/Presentation.vue";
import Doctor from "../components/Doctor.vue";
import WhatWeDo from "../components/WhatWeDo.vue";
import Mision from '../components/Mision.vue';
import Action from '../components/Action.vue';
import TheFooter from '../layout/TheFooter.vue';
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    WhatWeDo,
    Doctor,
    Presentation,
    Mision,
    Action,
    TheFooter
  },
};
</script>
<style lang="css" scoped>

@import url("../assets/css/style.css");
#home {
  background-image: url("../static/bg_home.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 600px;
  background-attachment: fixed;
}

@media only screen and (max-width: 767px){
  #home{
    background-attachment: initial !important;
  }
}


</style>
