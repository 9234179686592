import Firebase from "firebase/app";
// import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCmv5RSZzafft74066iMwOfNl60R3Ytz2E",
  authDomain: "worldkids-c3d93.firebaseapp.com",
  databaseURL: "https://worldkids-c3d93-default-rtdb.firebaseio.com",
  projectId: "worldkids-c3d93",
  storageBucket: "worldkids-c3d93.appspot.com",
  messagingSenderId: "134681641608",
  appId: "1:134681641608:web:13e51939fd6cb54680953d",
  measurementId: "G-4YCTE7VF8N",
};

// Initialize Firebase
if (!Firebase.apps.length) Firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default Firebase;
