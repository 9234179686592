<template>
  <section
    class="hero is-medium is-flex is-justify-content-center is-align-content-center"
    id="ceo"
  >
    <div class="section">
      <div class="columns is-mobile">
        <div class="column is-10 is-offset-1">
          <div class="columns">
            <div class="column is-flex is-align-item-center">
              <div
                data-aos="fade-right"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="1000"
              >
                <h3 class="subtitle is-uppercase">Generación Y</h3>
                <h1 class="title has-text-white has-text-weight-light">
                  "No solo participan de la educación y el cuidado de sus
                  hijos, si no que desean estar con ellos. Desean convertirse en
                  los padres perfectos."
                </h1>
              </div>
            </div>
            <div class="column">
              <figure
                class="image is-250x250 mauto"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="1000"
              >
                <img class="is-rounded" :src="require('../static/ceo.png')" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Presentation",
};
</script>

<style scoped>
#ceo {
  background-image: url("../static/bg_ceo.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
}
h3.subtitle.is-uppercase {
  color: rgb(255, 255, 255, 0.5) !important;
}
</style>