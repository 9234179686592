<template>
  <section class="section">
    <div class="container">
      <h3
        class="subtitle is-uppercase"
        data-aos="fade-right"
        data-aos-anchor-placement="top-bottom"
      >
        Bienvenidos todos 
      </h3>
      <h1
        class="title has-text-weight-bold"
        data-aos="fade-right"
        data-aos-delay="500"
        data-aos-duration="1000"
      >
        Gran comunidad donde puedes encontrar <br /><span class="has-text-info">
          apoyo</span
        >, <span class="has-text-info">información</span>,
        <span class="has-text-info">asesorías</span> y mucho más
      </h1>
      <div class="columns is-variable is-6">
        <div class="column">
          <p
            data-aos="fade-right"
            data-aos-delay="700"
            data-aos-duration="1000"
          >
            World kids está diseñada para padres comprometidos que han cambiado
            la forma de entender la paternidad, en donde encontrarán información
            científica en temas relacionados con la educación, alimentación,
            salud y la crianza asertiva, en donde podrán acceder lives con
            especialistas reconocidos en Colombia, podrán despejar dudas, y
            tendrán la oportunidad de capacitarte en temas de interés, además,
            contarán con el apoyo de una comunidad de padres con niños en edades
            similares que brindarán sus experiencias, apoyo para sortear
            situaciones cotidianas.
          </p>
        </div>
        <div class="column">
          <figure data-aos="fade-left" data-aos-delay="600" data-aos-duration="1000" >
            <img :src="require('../static/logoBig.png')" alt="" />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>|

<script>
export default {
  name: "Whatwedo",
};
</script>

<style lang="scss" scoped>
</style>